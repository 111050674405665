<template>
  <header>
    <div class="navbar-container d-flex align-items-center" >
      <img src="@/assets/images/logo/logo.png"  style=" margin-left:10vw; max-width: 27%;max-width: 27%">
      <ul class="nav-phone navbar-nav d-xl-none" style="margin-left: 35vw;    display: flex; flex-direction: row-reverse; max-width: 27%">
        <li class="nav-item-phone">
          <div
              class="nav-link"
              @click="config.isNavMenuHidden = !config.isNavMenuHidden"
          >
            <feather-icon
                icon="MenuIcon"
                size="34"
                v-bind:style= "[!config.isNavMenuHidden ? {'color': config.activeColor} : {'color':config.disabledColor}]"
            />
          </div>
        </li>
        <a style="margin-right: 2vw;"
           href="javascript:void(0) "
           class="nav-link nav-link-search"
           @click="showSearchBar = true"

        >
          <feather-icon
              icon="SearchIcon"
              size="34"
              v-bind:style= "[!config.isSearchDisabled ? {'color': config.activeColor} : {'color':config.disabledColor}]"
          />
        </a>
      </ul>
    </div>
    <div v-if="!config.isNavMenuHidden">
      <div class="navbar-container d-flex align-items-center" style="position: relative; display: flex; flex-direction: row; align-items: flex-start; margin-left: auto; top: 1.5vh">
        <a href="/home" class="button-1-phone" style="margin-left:12vw; ">
          <h class="font-2-phone " :style = "{ color: config.homeColor }">  首页 <br> Home</h>
        </a>
        <a href="/about" class="button-2-phone" style="margin-left:7vw">
          <h class="font-2-phone" :style = "{ color: config.aboutColor }"> 关于我们 <br> About us</h>
        </a>
        <a href="/product" class="button-2-phone" style="margin-left:7vw">
          <h class="font-2-phone" :style = "{ color: config.productColor }">购买产品  <br> Product</h>
        </a>
        <a href="/login" class="button-2-phone" style="margin-left:7vw">
          <h class="font-2-phone" :style = "{ color: config.mySpaceColor }"> 会员空间 <br> My space</h>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import boolean from 'vue-good-table/src/components/types/boolean'

export default {
  name: "HeaderComponent",
  props: {
    config : {}
  },

  data() {
    return {

    }
  }
}
</script>

<style>
nav-phone{
  position: absolute;
  width: 100%;
  left: 0px;
  top: 5%;
  height: 100%;
  height: 15%;
  display: flex;
}

sub-nav-phone{
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 60px;
  position: relative;
  width: 100%;
  left: 40%;
  top: 5%;
  backdrop-filter: blur(2px);
  display: flex;
}
.button-1-phone{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 20px;

  width: 47px;
  height: 42px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 41px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}.font-phone{
   width: 51px;
   height: 26px;

   font-family: 'Microsoft YaHei';
   font-style: normal;
   font-weight: 700;
   font-size: 10px;
   line-height: 13px;

   /* 成人+字体 主色 */



   /* Inside auto layout */

   flex: none;
   order: 0;
   flex-grow: 0;
 }
.button-2-phone{
  /* Auto layout */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 20px;

  width: 62px;
  height: 42px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 41px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.font-2-phone {
  width: 51px;
  height: 26px;

  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;

  /* 成人+字体 主色 */


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}


</style>