<template>
  <header >
    <div class="navbar-container d-flex align-items-center" >
      <img src="@/assets/images/logo/logo 400.png"  style="max-width: 20%;max-width: 20%">
        <div style="position: relative;  display:flex;flex-direction: row;align-items: flex-start;    margin-left: auto;">
          <a href="/home" class="button-1" :style = "{ color: config.homeColor }">
            <h class="font"> 首页 Home</h>
          </a>
          <a href="/about" class="button-2" :style = "{ color: config.aboutColor }">
            <h class="font-2"> 关于我们 About us</h>
          </a>
          <a href="/product" class="button-2" :style = "{ color: config.productColor }" >
            <h class="font-2">购买产品 Product</h>
          </a>
          <a href="/login" class="button-2" :style = "{ color: config.mySpaceColor }">
            <h class="font-2"> 会员空间 My space</h>
          </a>
        </div>
    </div>
  </header>
</template>

<script>
export default {
  name:'HomeHeader',
  props: {
    config : {}
  },

  data() {
    return {

    }
  }
}

</script>

<style>
  nav{
    position: absolute;
    width: 100%;
    left: 0px;
    top: 5%;
    height: 100%;
    height: 15%;
    display: flex;
  }

  sub-nav{
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 60px;
    position: relative;
    width: 100%;
    left: 40%;
    top: 5%;
    backdrop-filter: blur(2px);
    display: flex;
  }
  .button-1{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    margin-right:2vw;

    width: 78px;
    height: 75px;

    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 41px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }.font{
     width: 46px;
     height: 42px;

     font-family: 'Microsoft YaHei';
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 22px;

     /* 成人+字体 主色 */


     /* Inside auto layout */

     flex: none;
     order: 0;
     flex-grow: 0;
   }
  .button-2{
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    margin-right:2vw;
    width: 130px;
    height: 75px;

    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 41px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .font-2 {
    width: 87px;
    height: 39px;

    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;



    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

</style>