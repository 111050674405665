<template>
  <div v-if="!config.isMobile">
    <div class="container-fluid p-0">
    <div class="auth-bg-video-1">
      <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsinline="" autoplay="" muted=""
             loop="">
        <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
      </video>
    </div>
    <div class="home-page-content">
      <div style ="padding:25px"></div>
      <HomeHeader
          :config = "config"
      ></HomeHeader>
      <div class="glass-home">
        <div class="font-home">
          菲乐兰

          <div class="font-home-1">
            新西兰原装进口
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div v-else>
    <div class="container-fluid p-0">
      <div class="auth-bg-video-1">
        <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsinline="" autoplay="" muted=""
               loop="">
          <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
        </video>
        <PhoneHomeHeader
            :config = "config"
        ></PhoneHomeHeader>
        <div class="glass-home-phone">
          <div class="font-home-phone">
            菲乐兰

            <div class="font-home-1-phone">
              新西兰原装进口
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import HomeHeader from '@/views/home-page/components/HomeHeader.vue'
import PhoneHomeHeader from '@/views/home-page/components/PhoneHomeHeader.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {

    PhoneHomeHeader,
    HomeHeader,
    Swiper,
    SwiperSlide,
    BImg,
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted: function() {
    if (this._isMobile()) {
      this.config.isMobile = true;
    }else {
      this.config.isMobile = false;
    }
  },
  data() {
    const config = {
      isMobile : false,
      isNavMenuHidden : false,
      isSearchDisabled : true,
      homeColor :  "#036EB8",
      aboutColor: "#FFFFFF",
      productColor: "#FFFFFF",
      mySpaceColor: "#FFFFFF",
      activeColor: "#036EB8",
      disabledColor: "#FFFFFF",
      isOption_1: true,
      isOption_2: false,
      isOption_3: false,
      isOption_4: false,
    }
    return {
      config,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/milk-1.jpg') },
        { img: require('@/assets/images/banner/banner-4.jpg') },
        { img: require('@/assets/images/banner/banner-14.jpg') },
        { img: require('@/assets/images/banner/banner-3.jpg') },
        { img: require('@/assets/images/banner/banner-2.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.swiper-container{
  top: 15vh;
  position: relative;
  left: 20%;
  width: 60%;
  display: flex;
}
.home-page-content{
  position: absolute;
  top: 0;
  width: 100%;
}
.glass-home{

  position: absolute;
  width: 50vw;
  height: 40vh;
  left: 12vw;
  top: 28vh;
  background: radial-gradient(92.23% 92.23% at 50% 22.16%, rgba(235, 235, 235, 0.4) 0%, rgba(235, 235, 235, 0) 100%);
  filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.1));
  border-radius: 15px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.font-home {
  font-family: 'Microsoft YaHei UI';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #FCF7CD ;
  position: relative;
  top: 10vh;
  left: 5vw;
}
.font-home-1 {
  font-family: 'Microsoft YaHei UI';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  color: #F0F0F0 ;
  position: relative;
  top: 6vh;

}


.swiper-container-phone{
  top: 15vh;
  position: relative;
  left: 20%;
  width: 60%;
  display: flex;
}
.glass-home-phone{

  position: absolute;
  width: 65vw;
  height: 22vh;
  left: 45px;
  top: 36vh;
  background: radial-gradient(92.23% 92.23% at 50% 22.16%, rgba(235, 235, 235, 0.4) 0%, rgba(235, 235, 235, 0) 100%);
  filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.1));
  border-radius: 15px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.font-home-phone{
  font-family: 'Microsoft YaHei UI';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #FCF7CD ;
  position: relative;
  top: 2vh;
  left: 5vw;
}
.font-home-1-phone {
  font-family: 'Microsoft YaHei UI';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  color: #F0F0F0 ;
  position: relative;
  top: 6vh;

}
</style>